import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ThemeToggle from '../../ThemeToggle';

const FooterSection = () => {
  const [showtheme,setShowTheme ] = useState(false)
  return (
    <div>
      <div className="footer_section layout_padding">
        <div className="container">
          {/* <div className="footer_logo"><a href="index.html"><img src="images/footer-logo.png" /></a></div> */}
          <div className="footer_logo">
            <h1 className="text-light">GameStation</h1>
          </div>
          <div className="contact_section_2">
            <div className="row">
              <div className="col-sm-4">
                <h3 className="address_text">Contact Us</h3>
                <div className="address_bt">
                  <ul>
                    {/* <li>
                  <a href="#">
                    <i className="fa fa-map-marker" aria-hidden="true" /><span className="padding_left10">Address : Loram Ipusm</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-phone" aria-hidden="true" /><span className="padding_left10">Call : +01 1234567890</span>
                  </a>
                </li> */}
                    <li>
                      <a href="https://gamestasion.com/" target="_blank">
                        <i className="fa fa-envelope" aria-hidden="true" />
                        <span className="padding_left10">
                          Email : sherazalica24@gmail.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                {/* <div className="footer_logo_1"><a href="index.html"><img src="images/footer-logo.png" /></a></div> */}
                <div className="footer_logo_1">
                  <h1 className="text-light">GameStation</h1>
                </div>
                <p className="dummy_text">
                  At GameStation, we celebrate the vibrant world of gaming by
                  connecting enthusiasts of all levels. Our platform offers a
                  wealth of resources, including game reviews, industry news,
                  and expert tips to enhance your gameplay. We foster a
                  welcoming community where players can share experiences,
                  discuss favorites, and discover new adventures. Whether you’re
                  seeking the latest releases or a place to connect, GameStation
                  is your ultimate hub for everything gaming!
                </p>
              </div>
              <div className="col-sm-3">
                <div className="main">
                  <h3 className="address_text">Best Games</h3>
                  <p className="ipsum_text">Dragon Ball Z</p>
                  <p className="ipsum_text">The Witcher</p>
                  <p className="ipsum_text">God of War</p>
                </div>
              </div>
              <div className="col-sm-1">
                <div className="main">
                  {
                    showtheme ? <div className='mb-3 mt-3'> <ThemeToggle /> </div> : null
                  }
                  <div style={{cursor:"pointer"}} className='mb-3 mt-3' onClick={() => {setShowTheme(!showtheme)}}>
                  <svg
                  style={{position:"absolute"}}
                    fill="#f3f3f3"
                    height={30}
                    width={30}
                    version="1.1"
                    id="Capa_1"
                    // xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 54 54"
                    // xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M51.22,21h-5.052c-0.812,0-1.481-0.447-1.792-1.197s-0.153-1.54,0.42-2.114l3.572-3.571
		c0.525-0.525,0.814-1.224,0.814-1.966c0-0.743-0.289-1.441-0.814-1.967l-4.553-4.553c-1.05-1.05-2.881-1.052-3.933,0l-3.571,3.571
		c-0.574,0.573-1.366,0.733-2.114,0.421C33.447,9.313,33,8.644,33,7.832V2.78C33,1.247,31.753,0,30.22,0H23.78
		C22.247,0,21,1.247,21,2.78v5.052c0,0.812-0.447,1.481-1.197,1.792c-0.748,0.313-1.54,0.152-2.114-0.421l-3.571-3.571
		c-1.052-1.052-2.883-1.05-3.933,0l-4.553,4.553c-0.525,0.525-0.814,1.224-0.814,1.967c0,0.742,0.289,1.44,0.814,1.966l3.572,3.571
		c0.573,0.574,0.73,1.364,0.42,2.114S8.644,21,7.832,21H2.78C1.247,21,0,22.247,0,23.78v6.439C0,31.753,1.247,33,2.78,33h5.052
		c0.812,0,1.481,0.447,1.792,1.197s0.153,1.54-0.42,2.114l-3.572,3.571c-0.525,0.525-0.814,1.224-0.814,1.966
		c0,0.743,0.289,1.441,0.814,1.967l4.553,4.553c1.051,1.051,2.881,1.053,3.933,0l3.571-3.572c0.574-0.573,1.363-0.731,2.114-0.42
		c0.75,0.311,1.197,0.98,1.197,1.792v5.052c0,1.533,1.247,2.78,2.78,2.78h6.439c1.533,0,2.78-1.247,2.78-2.78v-5.052
		c0-0.812,0.447-1.481,1.197-1.792c0.751-0.312,1.54-0.153,2.114,0.42l3.571,3.572c1.052,1.052,2.883,1.05,3.933,0l4.553-4.553
		c0.525-0.525,0.814-1.224,0.814-1.967c0-0.742-0.289-1.44-0.814-1.966l-3.572-3.571c-0.573-0.574-0.73-1.364-0.42-2.114
		S45.356,33,46.168,33h5.052c1.533,0,2.78-1.247,2.78-2.78V23.78C54,22.247,52.753,21,51.22,21z M52,30.22
		C52,30.65,51.65,31,51.22,31h-5.052c-1.624,0-3.019,0.932-3.64,2.432c-0.622,1.5-0.295,3.146,0.854,4.294l3.572,3.571
		c0.305,0.305,0.305,0.8,0,1.104l-4.553,4.553c-0.304,0.304-0.799,0.306-1.104,0l-3.571-3.572c-1.149-1.149-2.794-1.474-4.294-0.854
		c-1.5,0.621-2.432,2.016-2.432,3.64v5.052C31,51.65,30.65,52,30.22,52H23.78C23.35,52,23,51.65,23,51.22v-5.052
		c0-1.624-0.932-3.019-2.432-3.64c-0.503-0.209-1.021-0.311-1.533-0.311c-1.014,0-1.997,0.4-2.761,1.164l-3.571,3.572
		c-0.306,0.306-0.801,0.304-1.104,0l-4.553-4.553c-0.305-0.305-0.305-0.8,0-1.104l3.572-3.571c1.148-1.148,1.476-2.794,0.854-4.294
		C10.851,31.932,9.456,31,7.832,31H2.78C2.35,31,2,30.65,2,30.22V23.78C2,23.35,2.35,23,2.78,23h5.052
		c1.624,0,3.019-0.932,3.64-2.432c0.622-1.5,0.295-3.146-0.854-4.294l-3.572-3.571c-0.305-0.305-0.305-0.8,0-1.104l4.553-4.553
		c0.304-0.305,0.799-0.305,1.104,0l3.571,3.571c1.147,1.147,2.792,1.476,4.294,0.854C22.068,10.851,23,9.456,23,7.832V2.78
		C23,2.35,23.35,2,23.78,2h6.439C30.65,2,31,2.35,31,2.78v5.052c0,1.624,0.932,3.019,2.432,3.64
		c1.502,0.622,3.146,0.294,4.294-0.854l3.571-3.571c0.306-0.305,0.801-0.305,1.104,0l4.553,4.553c0.305,0.305,0.305,0.8,0,1.104
		l-3.572,3.571c-1.148,1.148-1.476,2.794-0.854,4.294c0.621,1.5,2.016,2.432,3.64,2.432h5.052C51.65,23,52,23.35,52,23.78V30.22z"
                      />
                      <path
                        d="M27,18c-4.963,0-9,4.037-9,9s4.037,9,9,9s9-4.037,9-9S31.963,18,27,18z M27,34c-3.859,0-7-3.141-7-7s3.141-7,7-7
		s7,3.141,7,7S30.859,34,27,34z"
                      />
                    </g>
                  </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="social_icon">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/share/KgS3pHK8H8fhVb9Q/"
                  target="_blank"
                >
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
              </li>
              {/* <li>
            <a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a>
          </li> */}
              <li>
                <a
                  href="https://www.youtube.com/@SheraziTech-w3p"
                  target="_blank"
                >
                  <i className="fa fa-youtube" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a
                  href="https://whatsapp.com/channel/0029VafdsajKWEKrMZ1QmA3e"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* footer section end */}
      {/* copyright section start */}
      <div className="copyright_section">
        <div className="container">
          <p className="copyright_text">
            {new Date().getFullYear()} All Rights Reserved.{" "}
            <a href="https://gamestasion.com/">GameStation</a>
          </p>
          <p className="copyright_text center">
            <NavLink to={"/termscondition"} target="_blank">
              Terms and Conditions
            </NavLink>
          </p>
          <p className="copyright_text center">
            <NavLink to={"/privacy"} target="_blank">
              Privacy Policy
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterSection
