import React from "react";

const Terms = () => {
  return (
    <>
      <div className="">
        <div className="">
          <div className=" p-5">
            <h1 style={{ fontWeight: "bold", marginTop: "50px" }}>
              Terms and Conditions: <h6>(Last Updated: 20/10/2024)</h6>
            </h1>

            <div>
              <h2>1. Acceptance of Terms</h2>
              <p>
                By accessing or using the Site, we agree to be bound by these
                Terms and our Privacy Policy. If we do not agree with these
                Terms, we should not use the Site.
              </p>
              <h2>2. Use of the Site</h2>
              <p>
                We agree to use the Site in accordance with all applicable laws
                and regulations. We may not:
                <p>
                  - Use the Site for any unlawful purpose or in any way that
                  could damage, disable, overburden, or impair the Site
                </p>
                <p>
                  - Interfere with or disrupt the Site or servers or networks
                  connected to the Site.
                </p>
                <p>
                  - Attempt to gain unauthorized access to the Site or accounts
                  or systems connected to it.
                </p>
              </p>
              <h2>3. Content and Intellectual Property</h2>
              <p>
                All content on the Site, including text, graphics, logos,
                images, and software, is the property of Gamstasion or its
                content suppliers and is protected by intellectual property
                laws. We may not use, reproduce, or distribute any content from
                the Site without our express written permission.
              </p>
              <h2>4. Advertisements</h2>
              <p>
                The Site may display advertisements provided by third parties.
                We are not responsible for the content or accuracy of these
                advertisements. Any interactions or transactions with
                advertisers are solely between us and the advertiser.
              </p>
              <h2>5. User Content</h2>
              <p>
                We are solely responsible for any content we submit or post on
                the Site. We grant Gamstasion a non-exclusive, royalty-free,
                perpetual, and worldwide license to use, reproduce, modify, and
                display such content.
              </p>
              <h2>6. Limitation of Liability</h2>
              <p>
                To the fullest extent permitted by law, Gamstasion is not liable
                for any indirect, incidental, special, or consequential damages
                arising from or in connection with our use of the Site. This
                includes, but is not limited to, damages for loss of profits,
                data, or other intangible losses
              </p>
              <h2>7. Links to Third-Party Websites</h2>
              <p>
                The Site may contain links to other websites that are not
                operated by us. We do not endorse or control these third-party
                websites and are not responsible for their content or privacy
                practices.
              </p>
              <h2>8. Changes to Terms</h2>
              <p>
                We may update these Terms from time to time to reflect changes
                in our practices or legal requirements. We will post the new
                Terms on this page and update the date at the top. Our continued
                use of the Site constitutes acceptance of the updated Terms.
              </p>
              <h2>9. Termination</h2>
              <p>
                We reserve the right to terminate or suspend our access to the
                Site at our sole discretion, without notice, for any reason,
                including but not limited to a violation of these Terms.
              </p>
              <h2>10. Governing Law</h2>
              <p>
                These Terms are governed by and construed in accordance with the
                laws of Jurisdiction, without regard to its conflict of law
                principles.
              </p>
              <h2>11. Disclaimer</h2>
              <p>
                The Site is provided on an "as-is" and "as-available" basis. We
                make no representations or warranties of any kind, express or
                implied, regarding the Site or its content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
