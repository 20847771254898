import logo from './logo.svg';
import './App.css';
import Home from './app/pages/Home';
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import Articles from './app/pages/Articles';
import HeaderNavbar from './app/components/HeaderNavbar';
import Page404 from './app/pages/Page404';
import FooterSection from './app/components/FooterSection';
import PS1 from './app/pages/PS1';
import PS2 from './app/pages/PS2';
import PS3 from './app/pages/PS3';
import XBOX from './app/pages/XBOX';
import PC from './app/pages/PC';
import GameSpec from './app/components/GameSpec';
import { useEffect } from 'react';
import Terms from './app/pages/Terms';
import Privacy from './app/pages/Privacy';
function App() {
  const ps1gameData={
    "games": [
      {
        "id": "1",
        "title": "Final Fantasy VII",
        "posterImage": "images/finalfantacy.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          // "screenshot1": "https://via.placeholder.com/400x300?text=FF7+Screenshot+1",
          "screenshot1": "images/gameplay_screenshots/finalfantacys1.png",
          "screenshot2": "images/gameplay_screenshots/finalfantacys2.png",
          "screenshot3": "images/gameplay_screenshots/finalfantacys3.png"
        },
        "systemRequirements": "Final Fantasy VII requires a PlayStation 1 console and can be played with a standard or DualShock controller.",
        "detail": "Final Fantasy VII is an RPG that follows Cloud Strife as he battles against the evil Shinra Corporation and the dark figure Sephiroth. It features a rich story and a turn-based combat system that captivated players worldwide."
      },
      {
        "id": "2",
        "title": "Metal Gear Solid",
        "posterImage": "images/metalgearsolid.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/metalgears1.jpg",
          "screenshot2": "images/gameplay_screenshots/metalgears2.jpg",
          "screenshot3": "images/gameplay_screenshots/metalgears3.jpg"
        },
        "systemRequirements": "Metal Gear Solid is designed for the PlayStation 1 and can be played using either a standard or DualShock controller.",
        "detail": "Metal Gear Solid features Solid Snake infiltrating a facility to stop a nuclear threat. This game is known for its cinematic presentation and innovative gameplay, combining stealth and action in a compelling story."
      },
      {
        "id": "3",
        "title": "Resident Evil",
        "posterImage": "images/residentevil.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/residentevils1.png",
          "screenshot2": "images/gameplay_screenshots/residentevils2.png",
          "screenshot3": "images/gameplay_screenshots/residentevils3.png"
        },
        "systemRequirements": "Resident Evil requires a PlayStation 1 console and can be played with a standard controller.",
        "detail": "Resident Evil is a classic survival horror game set in a terrifying mansion filled with zombies. Players must solve intricate puzzles, manage limited resources, and uncover the dark secrets behind the Umbrella Corporation to survive the night."
      },
      {
        "id": "4",
        "title": "Crash Bandicoot",
        "posterImage": "images/crashbandicoot2.png",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/crashbandis1.jpg",
          "screenshot2": "images/gameplay_screenshots/crashbandis2.jpg",
          "screenshot3": "images/gameplay_screenshots/crashbandis3.jpg"
        },
        "systemRequirements": "Crash Bandicoot requires a PlayStation 1 console and can be played with a standard controller.",
        "detail": "Crash Bandicoot is a platformer featuring the titular character on a quest to thwart Dr. Neo Cortex's evil plans. The game is loved for its vibrant graphics, challenging levels, and quirky humor throughout the journey."
      },
      {
        "id": "5",
        "title": "Spyro the Dragon",
        "posterImage": "images/syprothedragon.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/spyros1.jpg",
          "screenshot2": "images/gameplay_screenshots/spyros2.jpg",
          "screenshot3": "images/gameplay_screenshots/spyros3.jpg"
        },
        "systemRequirements": "Spyro the Dragon requires a PlayStation 1 console and can be played with a standard controller.",
        "detail": "Spyro the Dragon is a 3D platformer that follows a young dragon on a quest to rescue his fellow dragons. With colorful worlds and engaging gameplay, it has become a beloved classic."
      },
      {
        "id": "6",
        "title": "Tomb Raider",
        "posterImage": "images/tombraider.jpg", 
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/tombs1.jpg",
          "screenshot2": "images/gameplay_screenshots/tombs2.jpg",
          "screenshot3": "images/gameplay_screenshots/tombs3.jpg"
        },
        "systemRequirements": "Tomb Raider requires a PlayStation 1 console and can be played with a standard controller.",
        "detail": "Tomb Raider introduces players to Lara Croft, an adventurous archaeologist. Players navigate through ancient tombs and ruins, solving puzzles and battling enemies in a quest for hidden treasures."
      },
      {
        "id": "7",
        "title": "Castlevania",
        "posterImage": "images/castlevania.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/castles1.jpg",
          "screenshot2": "images/gameplay_screenshots/castles2.jpg",
          "screenshot3": "images/gameplay_screenshots/castles3.jpg"
        },
        "systemRequirements": "Castlevania: Symphony of the Night requires a PlayStation 1 console and can be played with a standard controller.",
        "detail": "Castlevania: Symphony of the Night is a acclaimed action-adventure game that combines RPG elements with exploration. Players control Alucard as he battles Dracula's minions in a sprawling castle."
      },
      {
        "id": "8",
        "title": "Final Fantasy IX",
        "posterImage": "images/finalfantasyix.png",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/finals1.jpg",
          "screenshot2": "images/gameplay_screenshots/finals2.jpg",
          "screenshot3": "images/gameplay_screenshots/finals3.jpg"
        },
        "systemRequirements": "Final Fantasy IX requires a PlayStation 1 console and can be played with a standard or DualShock controller.",
        "detail": "Final Fantasy IX is a charming RPG that takes players on an epic journey through the land of Gaia. It features classic turn-based combat and a rich narrative filled with memorable characters."
      }
    ]
  }
  const ps2gameData = {
    "games": [
      {
        "id": "1",
        "title": "Grand Theft Auto: San Andreas",
        "posterImage": "images/gta_sandareas.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/gtaps2s1.jpg",
          "screenshot2": "images/gameplay_screenshots/gtaps2s2.jpg",
          "screenshot3": "images/gameplay_screenshots/gtaps2s3.jpg"
        },
        "systemRequirements": "Grand Theft Auto: San Andreas requires a PlayStation 2 console and can be played with a standard controller.",
        "detail": "In GTA: San Andreas, players assume the role of CJ, navigating a vast open world filled with crime, and opportunities in a story of personal redemption."
      },
      {
        "id": "2",
        "title": "Final Fantasy X",
        "posterImage": "images/finalfantacy.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/finalfantacys1.png",
          "screenshot2": "images/gameplay_screenshots/finalfantacys2.png",
          "screenshot3": "images/gameplay_screenshots/finalfantacys3.png"
        },
        "systemRequirements": "Final Fantasy X requires a PlayStation 2 console and can be played with a standard or DualShock controller.",
        "detail": "In Final Fantasy X, players follow Tidus and Yuna on a quest to defeat the monstrous Sin, exploring a rich world filled with unforgettable characters and an innovative turn-based battle system."
      },
      {
        "id": "3",
        "title": "Metal Gear Solid 3: Snake Eater",
        "posterImage": "images/metalgearsolid.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/metalgears1.jpg",
          "screenshot2": "images/gameplay_screenshots/metalgears2.jpg",
          "screenshot3": "images/gameplay_screenshots/metalgears3.jpg"
        },
        "systemRequirements": "Metal Gear Solid 3: Snake Eater requires a PlayStation 2 console and can be played with a standard or DualShock controller.",
        "detail": "Metal Gear Solid 3: Snake Eater places players in the role of Naked Snake, who must navigate the jungle to thwart a nuclear threat."
      },
      {
        "id": "4",
        "title": "Kingdom Hearts",
        "posterImage": "images/kingdomheart.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
         "screenshot1": "images/gameplay_screenshots/kingdomps2s1.jpg",
          "screenshot2": "images/gameplay_screenshots/kingdomps2s2.jpg",
          "screenshot3": "images/gameplay_screenshots/kingdomps2s3.jpg"
        },
        "systemRequirements": "Kingdom Hearts requires a PlayStation 2 console and can be played with a standard or DualShock controller.",
        "detail": "Kingdom Hearts beautifully combines characters from Disney and Final Fantasy as players control Sora, journeying through fantastical worlds to battle darkness and restore peace."
      },
      {
        "id": "5",
        "title": "Shadow of the Colossus",
        "posterImage": "images/shadowofclouses.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/shadowps2s1.jpg",
          "screenshot2": "images/gameplay_screenshots/shadowps2s2.jpg",
          "screenshot3": "images/gameplay_screenshots/shadowps2s3.jpg"
        },
        "systemRequirements": "Shadow of the Colossus requires a PlayStation 2 console and can be played with a standard controller.",
        "detail": "Shadow of the Colossus invites players to defeat giant creatures known as Colossi to restore life to a girl. Its minimalistic design and emotional storytelling create a unique experience."
      },
      {
        "id": "6",
        "title": "Resident Evil 4",
        "posterImage": "images/residentevilps2.png",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/residentevils1.png",
          "screenshot2": "images/gameplay_screenshots/residentevils2.png",
          "screenshot3": "images/gameplay_screenshots/residentevils3.png"
        },
        "systemRequirements": "Resident Evil 4 requires a PlayStation 2 console and can be played with a standard controller.",
        "detail": "Resident Evil 4 follows Leon S. Kennedy as he attempts to rescue the President's daughter from a mysterious cult. The game revolutionizes survival horror with its intense action and gripping atmosphere."
      },
      {
        "id": "7",
        "title": "God of War",
        "posterImage": "images/War.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
        "screenshot1": "images/gameplay_screenshots/warps2s1.png",
          "screenshot2": "images/gameplay_screenshots/warps2s2.png",
          "screenshot3": "images/gameplay_screenshots/warps2s3.png"
        },
        "systemRequirements": "God of War requires a PlayStation 2 console and can be played with a standard controller.",
        "detail": "God of War follows Kratos, a relentless Spartan warrior seeking revenge against the gods of Olympus. It features brutal combat, epic storytelling, and stunning visuals throughout."
      },
      {
        "id": "8",
        "title": "Jak and Daxter: The Precursor Legacy",
        "posterImage": "images/jack.jpg",
        "downloadlink": "https://www.google.com",
        "screenshots": {
          "screenshot1": "images/gameplay_screenshots/jackps2s1.png",
          "screenshot2": "images/gameplay_screenshots/jackps2s2.png",
          "screenshot3": "images/gameplay_screenshots/jackps2s3.png"
        },
        "systemRequirements": "Jak and Daxter requires a PlayStation 2 console and can be played with a standard controller.",
        "detail": "Jak and Daxter: The Precursor Legacy is a platformer featuring Jak and his sidekick Daxter on an adventure through a vibrant world filled with puzzles and enemies."
      }
    ]
}
const ps3gameData = {
  "games": [
    {
      "id": "1",
      "title": "The Last of Us",
      "posterImage": "images/lastofusps3.jpg",
      "downloadlink": "https://www.apple.com",
      "screenshots": {
       "screenshot1": "images/gameplay_screenshots/lastps3s1.jpg",
          "screenshot2": "images/gameplay_screenshots/lastps3s2.jpg",
          "screenshot3": "images/gameplay_screenshots/lastps3s3.jpg"
      },
      "systemRequirements": "The Last of Us requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "In The Last of Us, players navigate a post-apocalyptic world as Joel and Ellie, battling infected creatures and human adversaries while exploring themes of survival and love."
    },
    {
      "id": "2",
      "title": "Uncharted 2: Among Thieves",
      "posterImage": "images/unchartedps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/unchartedps3s1.jpg",
        "screenshot2": "images/gameplay_screenshots/unchartedps3s2.jpg",
        "screenshot3": "images/gameplay_screenshots/unchartedps3s3.jpg"
      },
      "systemRequirements": "Uncharted 2: Among Thieves requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "Uncharted 2: Among Thieves follows treasure hunter Nathan Drake on a thrilling adventure through exotic locales, combining platforming and storytelling."
    },
    {
      "id": "3",
      "title": "God of War III",
      "posterImage": "images/warps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/warps3s1.jpg",
          "screenshot2": "images/gameplay_screenshots/warps3s2.jpg",
          "screenshot3": "images/gameplay_screenshots/warps3s3.jpg"
      },
      "systemRequirements": "God of War III requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "In God of War III, players control Kratos as he seeks brutal vengeance against the gods of Olympus, featuring epic battles and stunning visuals in a mythological setting."
    },
    {
      "id": "4",
      "title": "Demon's Souls",
      "posterImage": "images/demonps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/demonps3s1.jpg",
        "screenshot2": "images/gameplay_screenshots/demonps3s2.jpg",
        "screenshot3": "images/gameplay_screenshots/demonps3s3.jpg"
      },
      "systemRequirements": "Demon's Souls requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "Demon's Souls is a challenging action RPG where players explore a haunting dark fantasy world, facing brutal enemies and complex combat mechanics that test skill and strategy."
    },
    {
      "id": "5",
      "title": "LittleBigPlanet",
      "posterImage": "images/littleplanetps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/littleps3s1.jpg",
          "screenshot2": "images/gameplay_screenshots/littleps3s2.jpg",
          "screenshot3": "images/gameplay_screenshots/littleps3s3.jpg"
      },
      "systemRequirements": "LittleBigPlanet requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "LittleBigPlanet is a creative platformer that allows players to create, share, and play levels, featuring charming graphics and a unique art style."
    },
    {
      "id": "6",
      "title": "Infamous",
      "posterImage": "images/infamousps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/infamousps3s1.jpg",
        "screenshot2": "images/gameplay_screenshots/infamousps3s2.jpg",
        "screenshot3": "images/gameplay_screenshots/infamousps3s3.jpg"
      },
      "systemRequirements": "Infamous requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "In Infamous, players control Cole MacGrath, a man with electric superpowers, navigating an open-world city while making choices that impact."
    },
    {
      "id": "7",
      "title": "Call of Duty Classic",
      "posterImage": "images/callofdutyps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "https://via.placeholder.com/400x300?text=COD+Classic+Screenshot+1",
        "screenshot2": "https://via.placeholder.com/400x300?text=COD+Classic+Screenshot+2",
        "screenshot3": "https://via.placeholder.com/400x300?text=COD+Classic+Screenshot+3"
      },
      "systemRequirements": "Call of Duty Classic requires Windows XP or later, 512 MB RAM, and a DirectX 9 compatible video card.",
      "detail": "Call of Duty Classic brings players back to the intense battles of World War II, featuring a gripping single-player campaign and classic multiplayer modes."
    },
    {
      "id": "8",
      "title": "Portal 2",
      "posterImage": "images/portalps3.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/portalps3s1.jpg",
        "screenshot2": "images/gameplay_screenshots/portalps3s2.jpg",
        "screenshot3": "images/gameplay_screenshots/portalps3s3.jpg"
      },
      "systemRequirements": "Portal 2 requires a PlayStation 3 console and can be played with a standard controller.",
      "detail": "Portal 2 is a unique puzzle-platformer where players solve challenges using a portal gun, combining humor and clever gameplay in a captivating story."
    }
  ]
}
const xboxgameData = {
  "games": [
    {
      "id": "1",
      "title": "Halo 5: Guardians",
      "posterImage": "images/halo5xbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/halo5xboxs1.png",
        "screenshot2": "images/gameplay_screenshots/halo5xboxs2.png",
        "screenshot3": "images/gameplay_screenshots/halo5xboxs3.png"
      },
      "systemRequirements": "Halo 5: Guardians requires an Xbox One console.",
      "detail": "In Halo 5: Guardians, players take on the roles of Master Chief and Spartan Locke in a quest to uncover a mystery threatening the galaxy."
    },
    {
      "id": "2",
      "title": "Gears of War",
      "posterImage": "images/gearofwarxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/gearxboxs1.jpg",
        "screenshot2": "images/gameplay_screenshots/gearxboxs2.jpg",
        "screenshot3": "images/gameplay_screenshots/gearxboxs3.jpg"
      },
      "systemRequirements": "Gears of War requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "Gears of War is a third-person shooter that follows Marcus Fenix and his squad as they battle against the monstrous Locust Horde in a gritty, post-apocalyptic world."
    },
    {
      "id": "3",
      "title": "Forza Motorsport 4",
      "posterImage": "images/forzaxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "https://via.placeholder.com/400x300?text=Forza+Screenshot+1",
        "screenshot2": "https://via.placeholder.com/400x300?text=Forza+Screenshot+2",
        "screenshot3": "https://via.placeholder.com/400x300?text=Forza+Screenshot+3"
      },
      "systemRequirements": "Forza Motorsport 4 requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "Forza Motorsport 4 is a racing simulation game that offers a wide variety of cars and tracks, featuring stunning graphics and realistic driving physics."
    },
    {
      "id": "4",
      "title": "Fable II",
      "posterImage": "images/fabelxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/fablexboxs1.jpg",
        "screenshot2": "images/gameplay_screenshots/fablexboxs2.jpg",
        "screenshot3": "images/gameplay_screenshots/fablexboxs3.jpg"
      },
      "systemRequirements": "Fable II requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "Fable II is an action RPG that allows players to shape their hero's destiny through choices, exploring a vibrant world filled with quests and dynamic interactions."
    },
    {
      "id": "5",
      "title": "Dead Rising",
      "posterImage": "images/deadxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/deadxboxs1.jpg",
        "screenshot2": "images/gameplay_screenshots/deadxboxs2.jpg",
        "screenshot3": "images/gameplay_screenshots/deadxboxs3.jpg"
      },
      "systemRequirements": "Dead Rising requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "Dead Rising is a survival horror game where players navigate a zombie-infested mall, using various weapons and items to survive while uncovering the story."
    },
    {
      "id": "6",
      "title": "Halo 3",
      "posterImage": "images/halo3xbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/halo3xboxs1.jpg",
        "screenshot2": "images/gameplay_screenshots/halo3xboxs2.jpg",
        "screenshot3": "images/gameplay_screenshots/halo3xboxs3.jpg"
      },
      "systemRequirements": "Halo 3 requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "In Halo 3, players continue the fight against the Covenant and the Flood as Master Chief, delivering a rich campaign and robust multiplayer experience."
    },
    {
      "id": "7",
      "title": "Rise of the Tomb Raider",
      "posterImage": "images/tombxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/risetombxboxs1.jpg",
        "screenshot2": "images/gameplay_screenshots/halo5xboxs2.jpg",
        "screenshot3": "images/gameplay_screenshots/halo5xboxs3.jpg"
      },
      "systemRequirements": "Rise of the Tomb Raider requires an Xbox 360 console and can be played with a standard controller.",
      "detail": "Rise of the Tomb Raider follows Lara Croft on her quest for ancient secrets, blending exploration, puzzle-solving, and action in breathtaking environments."
    },
    {
      "id": "8",
      "title": "Sea of Thieves",
      "posterImage": "images/seaxbox.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "https://via.placeholder.com/400x300?text=Sea+of+Thieves+Screenshot+1",
        "screenshot2": "https://via.placeholder.com/400x300?text=Sea+of+Thieves+Screenshot+2",
        "screenshot3": "https://via.placeholder.com/400x300?text=Sea+of+Thieves+Screenshot+3"
      },
      "systemRequirements": "Sea of Thieves requires an Xbox One console and can be played with a standard controller.",
      "detail": "Sea of Thieves is a multiplayer pirate adventure game where players explore a vast ocean, engage in ship battles, and hunt for treasure with friends."
    }
  ]
}
const pcgameData = {
  "games": [
    {
      "id": "1",
      "title": "The Witcher 3: Wild Hunt",
      "posterImage": "images/witcherpc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/witcherpcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/witcherpcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/witcherpcs3.jpg"
      },
      "systemRequirements": "Requires Windows 7 or later, 6 GB RAM, and NVIDIA GeForce GTX 660.",
      "detail": "An open-world RPG where players control Geralt of Rivia in a richly detailed fantasy world filled with quests and choices."
    },
    {
      "id": "2",
      "title": "Cyberpunk 2077",
      "posterImage": "images/cyberpunkpc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/cyberpcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/cyberpcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/cyberpcs3.jpg"
      },
      "systemRequirements": "Requires Windows 10, 8 GB RAM, and NVIDIA GeForce GTX 780.",
      "detail": "An open-world RPG set in a dystopian future where players navigate a sprawling city filled with choices and consequences."
    },
    {
      "id": "3",
      "title": "Dark Souls III",
      "posterImage": "images/darksoulpc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/darkpcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/darkpcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/darkpcs3.jpg"
      },
      "systemRequirements": "Requires Windows 7/8/10, 8 GB RAM, and NVIDIA GeForce GTX 750.",
      "detail": "An action RPG known for its challenging gameplay, atmospheric world, and deep lore as players explore a dark fantasy."
    },
    {
      "id": "4",
      "title": "NFS: Most Wanted",
      "posterImage": "images/nfspc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/nfspcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/nfspcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/nfspcs3.jpg"
      },
      "systemRequirements": "Requires Windows 7, Intel Core 2 Duo or AMD Athlon 64 X2, and NVIDIA GeForce 8800 series.",
      "detail": "An open-world racing game where players compete against rivals to become the most wanted driver, featuring police chases."
    },
    {
      "id": "5",
      "title": "DOOM Eternal",
      "posterImage": "images/doompc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/doompcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/doompcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/doompcs3.jpg"
      },
      "systemRequirements": "Requires Windows 10, 8 GB RAM, and NVIDIA GeForce GTX 1060.",
      "detail": "A fast-paced first-person shooter where players battle demons across a variety of hellish landscapes."
    },
    {
      "id": "6",
      "title": "Stardew Valley",
      "posterImage": "images/stardewpc.png",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/starpcs1.png",
        "screenshot2": "images/gameplay_screenshots/starpcs2.png",
        "screenshot3": "images/gameplay_screenshots/starpcs3.png"
      },
      "systemRequirements": "Requires Windows 7 or later, 2 GB RAM, and Intel Core 2 Duo.",
      "detail": "A farming simulation game where players build and manage a farm, engage with townspeople, and explore the countryside."
    },
    {
      "id": "7",
      "title": "Assassin's Creed Valhalla",
      "posterImage": "images/assassianpc.png",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/assassianpcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/assassianpcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/assassianpcs3.jpg"
      },
      "systemRequirements": "Requires Windows 10, 8 GB RAM, and NVIDIA GeForce GTX 960.",
      "detail": "An action-adventure game set during the Viking invasion of England, where players explore, raid, and build their settlement."
    },
    {
      "id": "8",
      "title": "Hades",
      "posterImage": "images/hadespc.jpg",
      "downloadlink": "https://www.google.com",
      "screenshots": {
        "screenshot1": "images/gameplay_screenshots/hadepcs1.jpg",
        "screenshot2": "images/gameplay_screenshots/hadepcs2.jpg",
        "screenshot3": "images/gameplay_screenshots/hadepcs3.jpg"
      },
      "systemRequirements": "Requires Windows 7 or later, 4 GB RAM, and NVIDIA GeForce GTX 650.",
      "detail": "A rogue-like dungeon crawler where players control Zagreus as he escapes the Underworld, encountering various gods."
    }
  ]
};

// useEffect(() => {
//   const handleContextMenu = (e) => {
//       e.preventDefault(); // Prevent the default right-click menu
//   };

//   // Add event listener
//   window.addEventListener('contextmenu', handleContextMenu);

//   // Clean up event listener on unmount
//   return () => {
//       window.removeEventListener('contextmenu', handleContextMenu);
//   };
// }, []);

  return (
    <>
    <HeaderNavbar/>
 {/* <ThemeToggle /> */}
    <Routes>
      {/* <Route path='/' element={<PrivateRoute/>}>
      <Route path='/home' element={<Home/>} />
      </Route> */}
      {/* <Route path='/login' element={<Login/>} /> */}
      <Route path='/' element={<Home ps3gameData = {ps3gameData}/>} />
      <Route path='/articles' element={<Articles/>} />
      {/* <Route path='/contactus' element={<Contactus/>} /> */}
      <Route path='/ps1' element={<PS1 gameData = {ps1gameData}/>} />
      <Route path='/ps2' element={<PS2 ps2gameData = {ps2gameData}/>} />
      <Route path='/ps3' element={<PS3 ps3gameData = {ps3gameData}/>} />
      <Route path='/xbox' element={<XBOX xboxgameData = {xboxgameData}/>} />
      <Route path='/pc' element={<PC pcgameData = {pcgameData}/>} />
      <Route path='/termscondition' element={<Terms/>} />
      <Route path='/privacy' element={<Privacy/>} />
      {/* <Route path='/spec' element={<GameSpec/>} /> */}
      <Route path='/*' element={<Page404/>} />
    </Routes>
    <FooterSection/>
   </>
  );
}

export default App;
