import React from 'react'

const Page404 = () => {
  return (
    <div className="App p-5">
      <h1 className='text-danger mt-5 '>Page not Found</h1>
      <h2 className='text-danger'>Sorry!!! The page you requested does not exist...</h2>
    </div>
  )
}

export default Page404
