import React from "react";
import { useState } from "react";
import AdSense from "../components/Adsence";

const Articles = () => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div>
      {/* header section start */}
      {/* <HeaderNavbar/> */}
      {/* header section end */}
      {/* about section start */}
      <div className="about_section layout_padding">
        <div className="container">
          <div className="about_section_main">
            <div className="row">
              <div className="col-md-1">
                <AdSense/>
              </div>
              <div className="col-md-5">
                <div className="about_taital_main">
                  <h1 className="about_taital">Latest Article</h1>
                  <p className="about_text">
                    The latest addition to the Dragon Ball Z gaming franchise,
                    Dragon Ball Z: Kakarot, continues to capture the hearts of
                    fans with its immersive storytelling and expansive gameplay.
                    Released initially in January 2020, this action RPG allows
                    players to experience the iconic story of Goku and his
                    friends through major sagas, from the Saiyan Saga to the
                    Majin Buu Saga.
                  </p>
                  <h1>Recent Updates</h1>
                  { showMore ?
                  <>
                  <p className="about_text">
                    Since its release, the game has received several updates and
                    DLCs, including the "A New Power Awakens" DLC, which
                    introduces characters from the Dragon Ball Super series and
                    allows players to engage in battles against formidable foes
                    like Beerus and Goku's Ultra Instinct form. Additionally,
                    fans can look forward to future expansions that promise to
                    introduce more characters and storylines, keeping the game
                    fresh and engaging for long-time fans of the series.
                  </p>
                  <h1>Conclusion</h1>
                  <p className="about_text">
                  Dragon Ball Z: Kakarot remains a standout title for Dragon Ball enthusiasts and RPG fans alike. Its blend of nostalgia, vibrant graphics, and engaging gameplay offers a captivating experience that continues to evolve. Whether you're reliving classic moments or exploring new adventures in the Dragon Ball universe, this game is a must-play for any fan.
                  </p>
                  </>
                  :
                  null
                    }
                  <div className="readmore_bt">
                    <a style={{color:"white"}} onClick={() => {setShowMore(!showMore)}}>{showMore ? "Read Less" :  "Read More"}</a>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <img src="images/DBZ.jpg" className="image_3" />
                </div>
              </div>
              <div className="col-md-1">
                    <AdSense/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about section end */}
    </div>
  );
};

export default Articles;
