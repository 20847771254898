import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="">
        <div className="">
          <div className=" p-5">
            <h1 style={{ fontWeight: "bold",marginTop:"50px" }}>
              Privacy Policy: <h6>(Last Updated: 20/10/2024)</h6>
            </h1>
            <p>
              Welcome to Gamstasion. This Privacy Policy explains how we
              collect, use, disclose, and safeguard your information when you
              visit our website{" "}
              <a href="https://gamestasion.com/"> https://www.gamestasion.com </a>. Please
              read this policy carefully.
            </p>
            <div>
              <h2>1. Information We Collect</h2>
              <p>
                We may collect personal information that you voluntarily provide
                to us when you register on the Site, make a purchase, or contact
                us. This information may include:
                <p>- Name</p>
                <p>- Email address</p>
                <p>- Phone number</p>
                <p>- Payment information</p>
                <p>- Any other information you provide to us</p>
              </p>
              <h2>2. How We Use Your Information</h2>
              <p>
                We use the information we collect in the following ways:
                <p>- To provide, operate, and maintain our Site</p>
                <p>- To improve, personalize, and expand our Site</p>
                <p>- To understand and analyze how you use our Site</p>
                <p>- To process transactions and manage orders</p>
                <p>
                  - To communicate with you, including responding to inquiries
                  and sending promotional materials
                </p>
                <p>- To detect, prevent, and address technical issues</p>
              </p>
              <h2>3. Sharing Your Information</h2>
              <p>
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties except in the following cases:
                <p>
                  - With service providers who assist us in operating our Site
                  and conducting our business, provided they agree to keep the
                  information confidential
                </p>
                <p>
                  - When required by law or to comply with legal obligations
                </p>
                <p>
                  - In connection with a merger, acquisition, or sale of all or
                  a portion of our assets
                </p>
              </p>
              <h2>4. Cookies and Tracking Technologies</h2>
              <p>
                We use cookies and similar tracking technologies to track
                activity on our Site and hold certain information. Cookies are
                files with a small amount of data which may include an anonymous
                unique identifier. You can instruct your browser to refuse all
                cookies or to indicate when a cookie is being sent. However, if
                we do not accept cookies, some parts of our Site may not
                function properly.
              </p>
              <h2>5. Security</h2>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is 100%
                secure, so we cannot guarantee absolute security.
              </p>
              <h2>6. Links to Other Websites</h2>
              <p>
                Our Site may contain links to other websites that are not
                operated by us. We do not have control over and assume no
                responsibility for the content, privacy policies, or practices
                of any third-party websites.
              </p>
              <h2>7. Children's Privacy</h2>
              <p>
                Our Site is not directed at individuals under the age of 13. We
                do not knowingly collect personal information from children
                under 13. If we become aware that we have collected personal
                information from a child under 13, we will take steps to delete
                such information.
              </p>
              <h2>8. Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the "Last Updated" date at the top. Your
                continued use of the Site after any changes constitutes your
                acceptance of the updated Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
