import React, { useEffect } from 'react';

const AdSense = () => {
  useEffect(() => {
    // Load the Google AdSense script
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up by removing the script
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      {/* <ins
        className="adsbygoogle"
        style={{ display: 'block', width: '100%', maxWidth: '728px', height: '90px' }}
        data-ad-client="ca-pub-XXXXXXXXXX"  // Replace with your AdSense client ID
        data-ad-slot="XXXXXXXXXX"            // Replace with your Ad slot ID
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>
        {(adsbygoogle = window.adsbygoogle || []).push({})}
      </script> */}
      <span style={{color:'red',fontWeight:"bold"}}>Adsencee</span>
    </div>
  );
};

export default AdSense;
